<template>
  <div class="biochemical">

    <el-form class="qjc-clearfix">
      <div class="form-cell" v-if="!isBj">
        <div class="insert-title qjc-wid-100">{{ $t('open.t36') }}</div>
        <el-form-item :label="$t('open.t42')" class="qjc-fl qjc-wid-50">
          <el-input v-model="formData.sbp" @input="mustNumber(0)" :placeholder="$t('goods.t16')"
            @blur="inputRange('sbp', 90, 180)">
            <div class="suffix qjc-fts-14" slot="suffix">
              mmHg
            </div>
          </el-input>
        </el-form-item>
        <el-form-item :label="$t('open.t43')" class="qjc-fl qjc-wid-50">
          <el-input v-model="formData.dbp" @input="mustNumber(1)" :placeholder="$t('goods.t16')"
            @blur="inputRange('dbp', 1, 90)">
            <div class="suffix qjc-fts-14" slot="suffix">
              mmHg
            </div>
          </el-input>
        </el-form-item>
      </div>
      <div class="form-cell">
        <div class="insert-title qjc-wid-100">{{ $t('open.t37') }}</div>
        <el-form-item :label="$t('open.t44')" class="qjc-fl qjc-wid-50">
          <el-input v-model="formData.examine[0].ve" @input="mustNumber(0)" :placeholder="$t('goods.t16')"
            @blur="inputRange(0, 0, 150)">
            <div class="suffix qjc-fts-14" slot="suffix">
              10³/μL（10⁹/L）
            </div>
          </el-input>
        </el-form-item>

        <el-form-item :label="$t('open.t45')" class="qjc-fl qjc-wid-50">
          <el-input v-model="formData.examine[1].ve" @input="mustNumber(1)" :placeholder="$t('goods.t16')"
            @blur="inputRange(1, 1, 25, 10, 250)">
            <div class="suffix qjc-fts-14" slot="suffix">
              <el-radio-group v-model="formData.examine[1].ut">
                <el-radio label="A">g/dL</el-radio>
                <el-radio label="B">g/L</el-radio>
              </el-radio-group>
            </div>
          </el-input>
        </el-form-item>
        <el-form-item :label="$t('open.t46')" class="qjc-fl qjc-wid-50">
          <el-input v-model="formData.examine[2].ve" @input="mustNumber(2)" :placeholder="$t('goods.t16')"
            @blur="inputRange(2, 1, 1800)">
            <div class="suffix qjc-fts-14" slot="suffix">
              10³/μl（10⁹/L）
            </div>
          </el-input>
        </el-form-item>
      </div>
      <div class="form-cell">
        <div class="insert-title qjc-wid-100">{{ $t('open.t38') }}</div>
        <el-form-item :label="$t('open.t47')" class="qjc-fl qjc-wid-50">
          <el-input v-model="formData.examine[3].ve" @input="mustNumber(3)" :placeholder="$t('goods.t16')"
            @blur="inputRange(3, 30, 700, 1.5, 40)">
            <div class="suffix qjc-fts-14" slot="suffix">
              <el-radio-group v-model="formData.examine[3].ut">
                <el-radio label="A">mg/dL</el-radio>
                <el-radio label="B">mmol/L</el-radio>
              </el-radio-group>
            </div>
          </el-input>
        </el-form-item>

        <el-form-item :label="$t('open.t48')" class="qjc-fl qjc-wid-50">
          <el-input v-model="formData.examine[4].ve" @input="mustNumber(4)" :placeholder="$t('goods.t16')"
            @blur="inputRange(4, 1, 25, 0, 47.5)">
            <div class="suffix qjc-fts-14" slot="suffix">
              <el-radio-group v-model="formData.examine[4].ut">
                <el-radio label="A">%</el-radio>
                <el-radio label="B">mmol/mol</el-radio>
              </el-radio-group>
            </div>
          </el-input>
        </el-form-item>
      </div>
      <div class="form-cell">
        <div class="insert-title qjc-wid-100">{{ $t('open.t39') }}</div>
        <el-form-item :label="$t('open.t49')" class="qjc-fl qjc-wid-50">
          <el-input v-model="formData.examine[5].ve" @input="mustNumber(5)" :placeholder="$t('goods.t16')"
            @blur="inputRange(5, 50, 1100, 1.2, 28.5)">
            <div class="suffix qjc-fts-14" slot="suffix">
              <el-radio-group v-model="formData.examine[5].ut">
                <el-radio label="A">mg/dL</el-radio>
                <el-radio label="B">mmol/L</el-radio>
              </el-radio-group>
            </div>
          </el-input>
        </el-form-item>

        <el-form-item :label="$t('open.t50')" class="qjc-fl qjc-wid-50">
          <el-input v-model="formData.examine[6].ve" @input="mustNumber(6)" :placeholder="$t('goods.t16')"
            @blur="inputRange(6, 1, 650, 0, 17)">
            <div class="suffix qjc-fts-14" slot="suffix">
              <el-radio-group v-model="formData.examine[6].ut">
                <el-radio label="A">mg/dL</el-radio>
                <el-radio label="B">mmol/L</el-radio>
              </el-radio-group>
            </div>
          </el-input>
        </el-form-item>

        <el-form-item :label="$t('open.t51')" class="qjc-fl qjc-wid-50">
          <el-input v-model="formData.examine[7].ve" @input="mustNumber(7)" :placeholder="$t('goods.t16')"
            @blur="inputRange(7, 1, 250, 0, 7)">
            <div class="suffix qjc-fts-14" slot="suffix">
              <el-radio-group v-model="formData.examine[7].ut">
                <el-radio label="A">mg/dL</el-radio>
                <el-radio label="B">mmol/L</el-radio>
              </el-radio-group>
            </div>
          </el-input>
        </el-form-item>

        <el-form-item :label="$t('open.t52')" class="qjc-fl qjc-wid-50">
          <el-input v-model="formData.examine[8].ve" @input="mustNumber(8)" :placeholder="$t('goods.t16')"
            @blur="inputRange(8, 1, 9000, 0, 110)">
            <div class="suffix qjc-fts-14" slot="suffix">
              <el-radio-group v-model="formData.examine[8].ut">
                <el-radio label="A">mg/dL</el-radio>
                <el-radio label="B">mmol/L</el-radio>
              </el-radio-group>
            </div>
          </el-input>
        </el-form-item>
      </div>
      <div class="form-cell">
        <div class="insert-title qjc-wid-100">{{ $t('open.t40') }}</div>
        <el-form-item :label="$t('open.t53')" class="qjc-fl qjc-wid-50">
          <el-input v-model="formData.examine[9].ve" @input="mustNumber(9)" :placeholder="$t('goods.t16')"
            @blur="inputRange(9, 1, 2000)">
            <div class="suffix qjc-fts-14" slot="suffix">
              IU/L(U/L)
            </div>
          </el-input>
        </el-form-item>

        <el-form-item :label="$t('open.t54')" class="qjc-fl qjc-wid-50">
          <el-input v-model="formData.examine[10].ve" @input="mustNumber(10)" :placeholder="$t('goods.t16')"
            @blur="inputRange(10, 1, 4000)">
            <div class="suffix qjc-fts-14" slot="suffix">
              IU/L(U/L)
            </div>
          </el-input>
        </el-form-item>

        <el-form-item :label="$t('open.t55')" class="qjc-fl qjc-wid-50">
          <el-input v-model="formData.examine[11].ve" @input="mustNumber(11)" :placeholder="$t('goods.t16')"
            @blur="inputRange(11, 1, 6500)">
            <div class="suffix qjc-fts-14" slot="suffix">
              IU/L(U/L)
            </div>
          </el-input>
        </el-form-item>

        <el-form-item :label="$t('open.t56')" class="qjc-fl qjc-wid-50">
          <el-input v-model="formData.examine[12].ve" @input="mustNumber(12)" :placeholder="$t('goods.t16')"
            @blur="inputRange(12, 1, 7, 10, 70)">
            <div class="suffix qjc-fts-14" slot="suffix">
              <el-radio-group v-model="formData.examine[12].ut">
                <el-radio label="A">g/dL</el-radio>
                <el-radio label="B">g/L</el-radio>
              </el-radio-group>
            </div>
          </el-input>
        </el-form-item>
      </div>
      <div class="form-cell">
        <div class="insert-title qjc-wid-100">{{ $t('open.t41') }}</div>
        <el-form-item :label="$t('open.t57')" class="qjc-fl qjc-wid-50">
          <el-input v-model="formData.examine[13].ve" @input="mustNumber(13)" :placeholder="$t('goods.t16')"
            @blur="inputRange(13, 0, 40, 0, 3600)">
            <div class="suffix qjc-fts-14" slot="suffix">
              <el-radio-group v-model="formData.examine[13].ut">
                <el-radio label="A">mg/dL</el-radio>
                <el-radio label="B">μmol/L</el-radio>
              </el-radio-group>
            </div>
          </el-input>
        </el-form-item>

        <el-form-item :label="$t('open.t58')" class="qjc-fl qjc-wid-50">
          <el-input v-model="formData.examine[14].ve" @input="mustNumber(14)" :placeholder="$t('goods.t16')"
            @blur="inputRange(14, 0, 20, 0, 1200)">
            <div class="suffix qjc-fts-14" slot="suffix">
              <el-radio-group v-model="formData.examine[14].ut">
                <el-radio label="A">mg/dL</el-radio>
                <el-radio label="B">μmol/L</el-radio>
              </el-radio-group>
            </div>
          </el-input>
        </el-form-item>

        <el-form-item :label="$t('open.t59')" class="qjc-fl qjc-wid-50">
          <el-input v-model="formData.examine[15].ve" @input="mustNumber(15)" :placeholder="$t('open.f47')"
            @blur="inputRange(15, 1, 300)">
            <div class="suffix qjc-fts-14" slot="suffix">
              ml/min/1.73m<sup>2</sup> （{{ $t('open.t60') }}）
            </div>
          </el-input>
        </el-form-item>
      </div>
      <div class="footer-btn">
        <el-button @click="handlePrev" class="prev qjc-fts-16 qjc-block">{{ $t('open.t12') }}</el-button>
        <el-button @click="handleSubmit" :loading="submiting" class="next qjc-fts-16 qjc-block">{{ $t('open.t61')
          }}</el-button>
      </div>


    </el-form>
  </div>
</template>

<script>
import { packageOpen } from '@/utils/axios'
import { Base64 } from 'js-base64'

export default {
  name: 'biochemical',
  data () {
    return {
      submiting: false,
      updateShow: false, //修改确认弹窗
      isSuper: false,
      userInfo: {}
    }
  },
  props: ['type', 'isBo', 'formData', 'clearData', "isBj"],
  inject: ['handleRefresh'],
  mounted () {
    let userInfo = JSON.parse(localStorage.getItem('userInfo')) ? JSON.parse(localStorage.getItem('userInfo')) : ''
    if (userInfo && userInfo.isSuper) {
      this.isSuper = userInfo.isSuper
    }
    this.userInfo = userInfo

    let examineArry = [
      { "ne": "WBC", "ve": "", "ut": "A" },
      { "ne": "HGB", "ve": "", "ut": "A" },
      { "ne": "PLT", "ve": "", "ut": "A" },
      { "ne": "FG", "ve": "", "ut": "B" },
      { "ne": "HBA1C", "ve": "", "ut": "A" },
      { "ne": "CHOL", "ve": "", "ut": "B" },
      { "ne": "LDLC", "ve": "", "ut": "B" },
      { "ne": "HDLC", "ve": "", "ut": "B" },
      { "ne": "TG", "ve": "", "ut": "B" },
      { "ne": "GOT", "ve": "", "ut": "A" },
      { "ne": "GPT", "ve": "", "ut": "A" },
      { "ne": "ALP", "ve": "", "ut": "A" },
      { "ne": "ALB", "ve": "", "ut": "B" },
      { "ne": "CRE", "ve": "", "ut": "B" },
      { "ne": "UA", "ve": "", "ut": "B" },
      { "ne": "EGFR", "ve": "", "ut": "A" }
    ]
    this.formData.examine.map((item, index) => {
      if (item.ve != '') {
        examineArry[index].ve = item.ve
        examineArry[index].ut = item.ut
      }
    })
    this.formData.examine = examineArry
  },
  methods: {
    handleUpdateClose () {
      this.updateShow = false;
    },
    handleSubmit () {
      this.submit()
    },
    // 确认提交
    submit () {
      // 添加操作人

      this.formData.operator = localStorage.getItem('mobile');
      if (!this.verifyNull()) {
        //是否存在未填写信息
        // 可为空data(二选一，后面再加验证)
        var white = ['yyuserid', 'mobile'];
        for (var key in this.formData) {
          if (white.indexOf(key) == -1) {
            if (!key) {
              this.$myAlert({
                type: 'fail',
                content: '存在未填写的信息'
              });
              return false;
            }
          }
        }
        //手机号与企业id至少一项需填写
        if (!this.formData.yyuserid && !this.formData.mobile) {
          this.$myAlert({
            type: 'fail',
            content: '手机号与企业用户ID至少填写一项'
          });
          return false;
        }

        this.submiting = true;

        const formData = JSON.parse(JSON.stringify(this.formData))

        if (formData.goods_id != '2') {
          delete formData.custom
          delete formData.selfcare
          delete formData.qntimes
          delete formData.qnpbf
          delete formData.qnmh
          delete formData.fbdisease
          delete formData.tscore
          delete formData.service
        }
        if (formData.goods_type != 'AIMB') {
          delete formData.prebust
          delete formData.prefat
          delete formData.prewaist
          delete formData.predis2
          delete formData.preweek
          delete formData.prehip
          delete formData.preweight
          delete formData.week
          delete formData.aps1
          delete formData.bsex
          delete formData.bweight
          delete formData.mage
        }
        if (formData.custom && formData.custom.indexOf('packageFB') <= -1) {
          delete formData.selfcare
          delete formData.qntimes
          delete formData.qnpbf
          delete formData.qnmh
          delete formData.fbdisease
          delete formData.tscore
          delete formData.service
        }

        if (this.isBj) {
          delete formData.sbp
          delete formData.dbp
          delete formData.sbpCombo
        }

        if (this.type == 'update') {
          formData.act = 'update'
        }
        if (formData.examdate == '') {
          formData.examdate = Date.parse(new Date()) / 1000
        }
        this.submiting = true;
        formData.birth = formData.birth.split('/').join('')
        formData.operator = this.userInfo.username ? this.userInfo.username : ''
        delete formData.package
        formData.area = '4401'
        const data = formData
        const type = formData.goods_type
        console.log('提交数据', data);
        packageOpen(formData).then(res => {

          this.submiting = false;

          if (res.code == 200) {
            this.updateShow = false;
            console.log("提交成功", res);
            const { id } = JSON.parse(Base64.decode(res.data))
            this.download(type, id)
            this.clearData();
            this.$emit('checkTab', 'Personal');

            this.handleRefresh();

          } else {
            this.updateShow = false;
            this.$myAlert({
              type: 'fail',
              content: res.msg
            });
          }
        });
      }

    },
    download (type, id) {
      console.log("下载报告", type, id);
      if (type == 'AIMB') {// 母婴
        window.open("/muying/report/lookReport.html?type=download&id=" + id);
        return
      } else if (type == 'AIZH') {
        window.open('/kyzc/lookReport.html?id=' + id);

        return
      } else if (type == 'AIFB') {
        window.open('/fb/lookReport.html?id=' + id);

        return
      } else if (type == 'AIBG') {
        window.open('/baijin/lookReport.html?id=' + id);

        return
      } else if (type == 'M22') {
        window.open('/m22/lookReport.html?id=' + id);

        return
      } else {
        let openUrl = this.$router.resolve({
          path: "/reportPrint/lookReport",
          query: {
            id: id
          }
        });

        window.open(openUrl.href);
      }

    },
    //验证不为空
    verifyNull () {
      let examineList = [
        { "ne": "WBC", "ve": "", "ut": "A", "name": "白细胞(WBC)", "en_name": "White Blood Cells (WBC)" },
        { "ne": "HGB", "ve": "", "ut": "A", "name": "血红蛋白(HGB)", "en_name": "Haemoglobin (HGB)" },
        { "ne": "PLT", "ve": "", "ut": "A", "name": "血小板(PLT)", "en_name": "Platelets (PLT)" },
        { "ne": "FG", "ve": "", "ut": "B", "name": "空腹血糖(FG)", "en_name": "Fasting Glucose(FG)" },
        { "ne": "HBA1C", "ve": "", "ut": "A", "name": "糖化血红蛋白(HBA1C)", "en_name": "Glycated hemoglobin (HBA1C)" },
        { "ne": "CHOL", "ve": "", "ut": "B", "name": "总胆固醇(CHOL)", "en_name": "Total Cholesterol (CHOL)" },
        { "ne": "LDLC", "ve": "", "ut": "B", "name": "低密度脂蛋白(LDLC)", "en_name": "Low-density lipoprotein (LDLC)" },
        { "ne": "HDLC", "ve": "", "ut": "B", "name": "高密度脂蛋白(HDLC)", "en_name": "High-density lipoprotein (HDL)" },
        { "ne": "TG", "ve": "", "ut": "B", "name": "甘油三酯(TG)", "en_name": "Triglycerides (TG)" },
        { "ne": "GOT", "ve": "", "ut": "A", "name": "谷草转氨酶GOT", "en_name": "Glutamine aminotransferase (GOT/AST)" },
        { "ne": "GPT", "ve": "", "ut": "A", "name": "谷丙转氨酶GPT", "en_name": "Glutathione (GPT/ALT)" },
        { "ne": "ALP", "ve": "", "ut": "A", "name": "碱性磷酸酶(ALP)", "en_name": "Alkaline phosphatase (ALP)" },
        { "ne": "ALB", "ve": "", "ut": "B", "name": "血清白蛋白(ALB)", "en_name": "Serum albumin (ALB)" },
        { "ne": "CRE", "ve": "", "ut": "B", "name": "肌酐(CRE)", "en_name": "Creatinine (CRE)" },
        { "ne": "UA", "ve": "", "ut": "B", "name": "尿酸(UA)", "en_name": "Uric acid (UA)" },
        { "ne": "EGFR", "ve": "", "ut": "A", "name": "肾小球滤过率(EGFR)", "en_name": "Glomerular filtration rate (EGFR)" }
      ];
      let lang = sessionStorage.getItem('theLanguage') ? sessionStorage.getItem('theLanguage') : 'zh'
      for (var i = 0; i < this.formData.examine.length; i++) {
        if (this.formData.examine[i].ve == '' && i != 15) {// i=15(肾小球过滤率)暂时可为空
          this.$myAlert({
            title: this.$t('open.f46'),
            content: this.$t('goods.t16') + '  ' + (lang == 'zh' ? examineList[i].name : examineList[i].en_name)
          });
          return true;
        }
      }

      return false;
    },

    //必须输入数字 参数对应examine下标
    mustNumber (index) {
      this.formData.examine[index].ve = this.formData.examine[index].ve.match(/\d/g) ? this.formData.examine[index].ve.match(/[\d || .]/g).join('') : '';
    },

    //输入偏差提示 参数1.data中examine下标  2.最小值(存在单位切换时为A单位最小值)  3.最大值(存在单位切换时为A单位最大值)  4.B最小值(存在单位切换时为B单位最小值) 5.B最大值(存在单位切换时为B单位最小值)
    inputRange (index, min, max, min2, max2) {
      if (index == 'sbp') {
        if (this.formData.sbp != '' && (this.formData.sbp < min || this.formData.sbp > max)) {
          this.$myAlert({
            title: this.$t('open.f60'),
            content: this.$t('open.f61')
          });
          return
        }
        return
      }
      if (index == 'dbp') {
        if (this.formData.dbp != '' && (this.formData.dbp < min || this.formData.dbp > max)) {
          this.$myAlert({
            title: this.$t('open.f60'),
            content: this.$t('open.f61')
          });
          return
        }
        return
      }
      if (this.formData.examine[index].ve != '') {
        if (min2 || max2 && this.formData.examine[index].ut == 'B') {//存在单位切换且为单位为B值时
          if (this.formData.examine[index].ve < min2 || this.formData.examine[index].ve > max2) {
            this.$myAlert({
              title: this.$t('open.f60'),
              content: this.$t('open.f61')
            });
          }
        } else {
          if (this.formData.examine[index].ve < min || this.formData.examine[index].ve > max) {
            this.$myAlert({
              title: this.$t('open.f60'),
              content: this.$t('open.f61')
            });
          }
        }
      }

    },

    handlePrev () {
      if (this.formData.package == '2' && this.formData.custom.indexOf('packageFB') > -1) {
        this.$emit('checkTab', 'InsertFb')
      } else if (this.formData.package == '3') {
        this.$emit('checkTab', 'Newborn')
      } else {
        this.$emit('checkTab', 'Bodily')
      }

    },
  }
}
</script>

<style scoped>
.biochemical>>>.el-form-item__label {
  width: 190px;
  font-size: 14px;
  line-height: 14px;
  /* margin-bottom: 10px; */
  text-align: left;
  color: #777F8F;
  float: none;
}

.biochemical>>>.el-input {
  height: 32px;
  line-height: 32px;
}

.biochemical>>>.el-input input {
  height: 32px;
  line-height: 14px;
  font-size: 14px;
  padding: 10px 12px;
}

.biochemical>>>.el-radio__label {
  font-size: 14px;
}
</style>
<style lang="scss" scoped>
.biochemical {
  padding-top: 32px;

  .form-cell {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
  }

  .insert-title {
    position: relative;
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #293033;
    line-height: 16px;
    padding-bottom: 15px;
    border-bottom: 1px solid #E5E8F5;
    margin-bottom: 23px;

    &::before {
      position: absolute;
      left: -24px;
      top: 0;
      content: '';
      width: 4px;
      height: 20px;
      background-color: #6883FB;
    }
  }

  .el-form {
    margin-top: 22px;

    .el-form-item {
      display: flex;
      align-items: center;

      margin-bottom: 24px;

      .el-input {
        width: 376px;

        .el-radio {
          font-weight: normal;
          color: #777F8F;
        }

        .suffix {
          color: #777F8F;
          margin-right: 16px;

          sub {
            display: inline-block;
            vertical-align: baseline;
            font-size: inherit;
            transform: translateY(9%)scale(.65);
          }

          sup {
            display: inline-block;
            vertical-align: top;
            font-size: inherit;
            transform: translateY(-9%)scale(.65);
          }
        }
      }

      .submit {
        width: 200px;
        height: 48px;
        margin-top: 40px;
      }
    }
  }

}

.footer-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
}

.next {
  // width: 96px;
  padding: 0 10px;
  height: 34px;
  border-radius: 17px;
  border: none;
  font-size: 14px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #fff;
}

.prev {
  // width: 96px;
  padding: 0 10px;
  height: 34px;
  border-radius: 17px;
  border: 1px solid #6883FB;
  font-size: 14px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #6883FB;
  background-color: #fff;
  margin-right: 24px;
}


.cancel-btn {
  width: 80px;
  height: 40px;
  color: #606266;
  background-color: #fff;
}

.submit-btn {
  width: 80px;
  height: 40px;
  background-color: #6681FA;
  border: none;
}
</style>
